<template>
  <div>
    <CRow>
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <CCard class="col-xl-12 padding-0">
        <CCardHeader>
            <CButton color="primary" class="btn-block col-xl-2" :to="{ name: 'Add Users' }"><v-icon name="pencil-alt"/> Add User</CButton>
        </CCardHeader>
        <CCardBody class="" style="text-align: center;">
            <CSpinner
                v-if="isLoading"
                color="info"
                style="width:3rem;height:3rem;"
            />
            <span v-if="!isLoading && this.users.length < 1">Data Belum Ada</span>
            <CDataTable
                v-if="this.users.length > 0"
                :hover="true"
                :striped="true"
                :border="true"
                :small="true"
                :fixed="true"
                :sorter="true"
                :items="this.users"
                :fields="fields"
                style="text-align: center;"
                class="v-mid-tab"
                tableFilter
                itemsPerPageSelect
                :itemsPerPage="this.itemperpage"
                :pagination="{ doubleArrows: true, align: 'end'}"
            >
            <template v-slot:profile_picture="data">
                <td>
                    <div class="c-avatar">
                        <img :src="data.item.profile_picture?data.item.profile_picture:'img/avatars/profile_img.svg'" class="c-avatar-img-h100" alt=""/>
                    </div>
                </td>
            </template>
            <template v-slot:privilege="data">
                <td>
                    {{data.item.role?data.item.role.name:''}}
                </td>
            </template>
            <template v-slot:actions="data">
                <td>
                    <CButton :color="data.item.is_active?'primary':'secondary'" class="btn btn-sm mr-1" :to="{name: 'Detail Users', params: {id: data.item.uuid}}">
                        <v-icon name="user" class="mr-1 v-mid"/> View
                    </CButton>
                    <CButton color="info" class="btn btn-sm mr-1" :to="{name: 'Edit Users', params: {id: data.item.uuid}}">
                        <v-icon name="pencil-alt" class="mr-1 v-mid"/> Edit
                    </CButton>
                    <CButton v-if="data.item.id!=1" color="danger" class="btn btn-sm mr-1" @click="removeData(data.item.uuid)">
                        <v-icon name="trash" class="mr-1 v-mid"/> Delete
                    </CButton>
                </td>
            </template>
            </CDataTable>
        </CCardBody>
      </CCard>
    </CRow>
    <CModal
      title="Modal Delete"
      :show.sync="modalDelete"
      size="sm"
    >
      <span>Are you sure remove this data ?</span>
      <template #header>
        <h6 class="modal-title">Remove Data</h6>
        <CButtonClose @click="modalDelete = false"/>
      </template>
      <template #footer>
        <CButton @click="modalDelete = false" color="light" class="text-left">Cancel</CButton>
        <CButton @click="destroyData(delId)" color="danger">Yes</CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'Users',
  components: {
  },
  props: {
      fields: {
        type: Array,
        default () {
            return [
                { key: 'profile_picture', label: '#' },
                { key: 'name', label: 'Full Name' },
                { key: 'email', label: 'Email' },
                { key: 'nrp', label: 'NRP' },
                { key: 'privilege', label: 'Privilege' },
                { key: 'actions', label: '' }
            ]
        }
      },
  },
  data () {
    return {
        isLoading: false,
        modalDelete: false,
        find: '',
        page: '',
        itemperpage: 10,
        users: [],
        delId: ''
    }
  },
  watch: {
    modalDelete: {
      handler: function (val, oldVal) {
        if(!val){ this.delId = '' }
      }
    }
  },
  created() {
    this.reRender()
  },
  methods: {
    ...mapActions('users', ['indexUSR', 'destroyUSR']),
    reRender(){
      this.isLoading = true
      this.users = []
      this.indexUSR().then((response) => {
        this.users = response.length > 0 ? response : []
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoading = false
      })
    },
    destroyData(uuid){
      this.modalDelete = false
      this.destroyUSR(uuid).then((response) => {
        this.reRender()
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoading = false
      })
    },
    removeData(uuid){
      this.modalDelete = true
      this.delId = uuid
    }
  }
}
</script>
<style scoped>
  .c-avatar {
    display: inline-block;
  }
</style>